/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from "theme-ui";
import { withPrefix } from "gatsby";
import ProjectCardTagSelector from "../components/project-card-tag-selector";

type CustomProjectCardProps = {
  title: string
  date: string
  imgsrc: string // Optional
  children: React.ReactNode
  whatisit: string
  currentTag: string; // Selector for tag list below: which one(s) are visible?
  tags: list 
  text: string
  links: list  // Optional: links to be added to end of card
}

function getGradientFor(whatisit) {
  if (whatisit == "Website" || whatisit == "Paper" || whatisit == "UberCheats") {
    return "linear-gradient(45deg,#D4145A 0%,#ab7829 100%)";
  }
  if (whatisit == "Chrome Extension" || whatisit == "Patent" || whatisit == "dashcam.bike") {
    return "linear-gradient(to right, #662D8C 0%, #ED1E79 100%)";
  }
  if (whatisit == "Twitter Bot" || whatisit == "Poster" || whatisit == "Ranked Choice Voting") {
    return "linear-gradient(45deg,#044916 30%,#8f860d 100%)";
  }
  if (whatisit == "Research Application" || whatisit == "Thesis") {
    return "linear-gradient(45deg,#641091 40%,#0b40ab 100%)";
  }

  return "linear-gradient(45deg,#662D8C 20%,#96144d 100%)";
}

const CustomProjectCard = ({ title, date, imgsrc=null, children, whatisit, currentTag, tags=['Featured'], links=[], text="" }: CustomProjectCardProps) => {
  let imageDiv = (<></>);
  if (imgsrc != null) {
    imageDiv = (
      <div sx={{ float: `left`, marginRight: `20px`, maxWidth: `30%` }}>
        <img src={withPrefix(imgsrc)}
             sx={{width: `128px`, maxWidth: `100%`}}
             alt={title}
             />
      </div>
    );

    if (links.length > 0) {
      imageDiv = (<a href={links[0].url} target="_blank">{imageDiv}</a>);
    }
  }

  return (
  <div
    sx={{
      width: `100%`,
      boxShadow: `lg`,
      position: `relative`,
      ariaLabel: {title},
      textDecoration: `none`,
      borderRadius: `lg`,
      display: tags.includes(currentTag) || currentTag == 'All' ? 'block' : 'none',
      px: 4,
      py: [2, 4],
      color: `white`,
      background: getGradientFor(whatisit),
      transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
      "&:hover": {
        color: `white !important`,
        transform: `translateY(-5px)`,
        boxShadow: `xl`,
      },
    }}>
    {imageDiv}
    <div>
      <div
        sx={{
          textTransform: `uppercase`,
          letterSpacing: `wide`,
          pb: 2,
          fontSize: [1, 3],
          fontWeight: `medium`,
          lineHeight: 1
        }}
      >
        {title}
      </div>
      <div sx={{
          fontSize: `0.7rem`,
          fontWeight: `light`,
          fontStyle: `italic`
      }}>
        {whatisit}, {date}
      </div>
      <div sx={{ opacity: 0.85, textShadow: `0 2px 10px rgba(0, 0, 0, 0.3)` }} dangerouslySetInnerHTML={{ __html: text }}/>
      <div sx={{ fontSize: `0.8em`, marginTop: `10px` }} >
        {
          links.map((link, index) => {
            const bar = index != links.length - 1 ? '|' : '';
            return <><a href={link.url} target="_blank">{link.name}</a> {bar} </>;
          })
        }
      </div>
    </div>
  </div>
)
}

export const CustomProjectCardList = ({listOfProps, currentTag}: {}) => {
  return (<>
    {listOfProps.map((props, index) => {
        return <CustomProjectCard {...props} currentTag={currentTag}></CustomProjectCard>
    })}
  </>
  )
}

export default CustomProjectCard
